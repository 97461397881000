@font-face {
  font-family: "FranceTV Brown";
  src: url("/assets/fonts/FranceTVBrown-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FranceTV Brown";
  src: url("/assets/fonts/FranceTVBrown-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "FranceTV Brown";
  src: url("/assets/fonts/FranceTVBrown-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "FranceTV Brown";
  src: url("/assets/fonts/FranceTVBrown-Light.otf") format("opentype");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: "FranceTV Brown";
  src: url("/assets/fonts/FranceTVBrown-Thin.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
