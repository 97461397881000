@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';


/* Custom Mat Chip List */
/*
How to use : add on tv-container-rate-chip-list class mat-chip-list
*/

.tv-container-rate-chip-list {
  display: flex;
  flex-direction: column;
  border-radius: $tv-xs-border-radius;
  width: auto; /* Ajuste selon le contenu, ou laisse auto si nécessaire */

  .mat-chip.mat-standard-chip {
    background-color: $tv-blueish-grey-shade-6;
    min-height: 22px; /* Hauteur minimale de la puce */
    min-width: 20px; /* Largeur minimale */
    padding: 0 5px; /* Ajoute un peu le padding horizontal */
    color: $tv-blueish-grey-shade-5;
    border-radius: $tv-xs-border-radius;
    //width: 15px;      /* Largeur fixe (ajuste selon besoin) */
    width: auto;
    font-size: 15px; /* Taille du texte */

    /* Centrer le texte horizontalement et verticalement */
    display: flex;
    justify-content: center; /* Centre le contenu horizontalement */
    align-items: center; /* Centre le contenu verticalement */
    text-align: center; /* Optionnel, mais utile pour forcer l'alignement du texte */
  }

  .mat-chip.mat-standard-chip::after {
    background: $tv-white;
    border-radius: $tv-xs-border-radius;
    width: 15px;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: $tv-white;
    color: $tv-blueish-grey-shade-4;
    border-radius: $tv-xs-border-radius;
    width: 15px;
    padding: 0 5px; /* Ajuste le padding pour que le texte soit bien centré */

    /* Centrer le texte dans la puce sélectionnée */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
