.btn--rounded {
  box-sizing: border-box;
  border-radius: 24.5px !important;
  padding: 10px 28px 10px 28px !important;
}

.btn--rounded--stroked {
  box-sizing: border-box;
  border: 2px solid #222323 !important;
  border-radius: 24.5px !important;
  padding: 10px 28px 10px 28px !important;
}
