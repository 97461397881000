@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';

.custom-tv-tooltip {
  background-color: $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-1 !important;
  font-size: $tv-text-s-regular;
  border-radius: $tv-xs-border-radius;
}
