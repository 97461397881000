$tv-border-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

/*------------------------------------------------- NEW VARS ----------------------------------------------------*/

/* ------ Border Radius ------- */
$tv-xs-border-radius: 5px;
$tv-s-border-radius: 10px;
$tv-m-border-radius: 16px;
$tv-l-border-radius: 20px;
$tv-xl-border-radius: 25px;
$tv-xxl-border-radius: 100px;

/* ------ Padding ------- */
$tv-xxs-padding-1: 1px;
$tv-xxs-padding-14: 14px;
$tv-xxs-padding-32: 32px;
$tv-xxs-padding-60: 60px;
$tv-xs-padding: 5px;
$tv-s-padding: 10px;
$tv-m-padding: 15px;
$tv-l-padding: 20px;
$tv-xl-padding: 25px;

/* ------ Margin ------- */
$tv-xs-margin: 5px;
$tv-s-margin: 10px;
$tv-m-margin: 15px;
$tv-l-margin: 20px;
$tv-xl-margin: 25px;

/* ------ Gap ------- */
$tv-xxs-gap: 2px;
$tv-xs-gap: 5px;
$tv-s-gap: 10px;
$tv-m-gap: 15px;
$tv-l-gap: 20px;
$tv-xl-gap: 25px;

/* ------ Witdh ------ */
$tv-150-width: 150px;
$tv-25-width: 25px;
