@import "./vars.scss";

.container {
  margin: auto;
  width: 1180px;
}

h1 {
  color: #222323;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 44px;
}

.z-1200 {
  z-index: 1200 !important;
}

.position-relative {
  position: relative !important;
  top: 0;
  left: 0;
}
.position-absolute {
  position: absolute !important;
  top: 0;
  left: 0;
}
.position-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.text-small {
  font-size: 12px !important;
}

.text-large {
  font-size: 18px !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-very-light {
  font-weight: 100 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-very-bold {
  font-weight: 900 !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.clickable {
  cursor: pointer;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.label {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.full-width {
  width: 100% !important;
}

@mixin p-x {
  @for $i from 0 through 8 {
    .p-#{$caliber * $i} {
      padding: #{$caliber * $i}px !important;
    }
  }
}
@mixin p-x-t {
  @for $i from 0 through 8 {
    .p-#{$caliber * $i}-t {
      padding-top: #{$caliber * $i}px !important;
    }
  }
}
@mixin p-x-r {
  @for $i from 0 through 8 {
    .p-#{$caliber * $i}-r {
      padding-right: #{$caliber * $i}px !important;
    }
  }
}
@mixin p-x-b {
  @for $i from 0 through 8 {
    .p-#{$caliber * $i}-b {
      padding-bottom: #{$caliber * $i}px !important;
    }
  }
}
@mixin p-x-l {
  @for $i from 0 through 8 {
    .p-#{$caliber * $i}-l {
      padding-left: #{$caliber * $i}px !important;
    }
  }
}
@mixin m-x {
  @for $i from 0 through 8 {
    .m-#{$caliber * $i} {
      margin: #{$caliber * $i}px !important;
    }
  }
}
@mixin m-x-t {
  @for $i from 0 through 8 {
    .m-#{$caliber * $i}-t {
      margin-top: #{$caliber * $i}px !important;
    }
  }
}
@mixin m-x-r {
  @for $i from 0 through 8 {
    .m-#{$caliber * $i}-r {
      margin-right: #{$caliber * $i}px !important;
    }
  }
}
@mixin m-x-b {
  @for $i from 0 through 8 {
    .m-#{$caliber * $i}-b {
      margin-bottom: #{$caliber * $i}px !important;
    }
  }
}
@mixin m-x-l {
  @for $i from 0 through 8 {
    .m-#{$caliber * $i}-l {
      margin-left: #{$caliber * $i}px !important;
    }
  }
}

@include p-x;
@include p-x-t;
@include p-x-r;
@include p-x-b;
@include p-x-l;
@include m-x;
@include m-x-t;
@include m-x-r;
@include m-x-b;
@include m-x-l;
