@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';


/* -----------------------------  Mixins -----------------------------  */
@mixin ns-button-default {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
  font: inherit;
  appearance: none;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $tv-xxl-border-radius;
}

@mixin ns-large-button {
  padding: 8px 16px;
  font: $tv-text-button-large;
}

@mixin ns-small-button {
  padding: 2px 8px;
  font: $tv-text-button-small;
  mat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
}

/* ----------------------------- Large Buttons -----------------------------  */
/* ------ Large Buttons On Light Background ------  */

/* 01. Primary Large Button on light background */
.tv-ns-primary-large-button-on-light-bg {
  @include ns-button-default;
  @include ns-large-button;

  background-color: $tv-accent-01-red;
  border: 1px solid $tv-accent-01-red;
  color: $tv-white;
}

.tv-ns-primary-large-button-on-light-bg:hover {
  background-color: $tv-accent-01-red-hover;
  border: 1px solid $tv-accent-01-red-hover;
}

.tv-ns-primary-large-button-on-light-bg:disabled {
  background-color: $tv-blueish-grey-shade-6;
  border: 1px solid $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-5;
}

/* 02. Primary Large Button on dark background */
.tv-ns-primary-large-button-on-dark-bg {
  @include ns-button-default;
  @include ns-large-button;

  border: 1px solid $tv-accent-01-red;
  background-color: $tv-accent-01-red;
  color: $tv-white;
}

.tv-ns-primary-large-button-on-dark-bg:hover {
  border: 1px solid $tv-accent-01-red;
  background-color: transparent;
}

.tv-ns-primary-large-button-on-dark-bg:disabled {
  border: 1px solid $tv-blueish-grey-shade-4;
  background-color: $tv-blueish-grey-shade-6-transparent-10;
  color: $tv-blueish-grey-shade-5;
}

/* 03. Secondary Large Button on light background */
.tv-ns-secondary-large-button-on-light-bg {
  @include ns-button-default;
  @include ns-large-button;

  background-color: $tv-white;
  color: $tv-blueish-grey-shade-4;
  border: 1px solid $tv-blueish-grey-shade-4;
}

.tv-ns-secondary-large-button-on-light-bg:hover {
  background-color: $tv-blueish-grey-shade-4;
  border: 1px solid $tv-blueish-grey-shade-4;
  color: $tv-white;
}

.tv-ns-secondary-large-button-on-light-bg:disabled {
  background-color: $tv-blueish-grey-shade-6;
  border: 1px solid $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-5;
}

/* 04. Secondary Large Button on dark background */
.tv-ns-secondary-large-button-dark-bg {
  @include ns-button-default;
  @include ns-large-button;

  background-color: transparent;
  color: $tv-blueish-grey-shade-6;
  border: 1px solid $tv-blueish-grey-shade-6;
}

.tv-ns-secondary-large-button-dark-bg:hover {
  border: 1px solid $tv-blueish-grey-shade-6;
  background-color: $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-4;
}

.tv-ns-secondary-large-button-dark-bg:disabled {
  border: 1px solid $tv-blueish-grey-shade-4;
  background-color: $tv-blueish-grey-shade-6-transparent-10;
  color: $tv-blueish-grey-shade-5;
}

/* Validation Large Button */
.tv-ns-validation-large-button {
  @include ns-button-default;
  @include ns-large-button;

  background-color: $tv-success-green;
  border: 1px solid $tv-success-green;
  color: $tv-white;
}

.tv-ns-validation-large-button:hover {
  background-color: $tv-success-green-hover;
  border: 1px solid $tv-success-green-hover;
}

.tv-ns-validation-large-button:disabled {
  background-color: $tv-blueish-grey-shade-6;
  border: 1px solid $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-5;
}

/* Validation Small Button */
.tv-ns-validation-small-button {
  @include ns-button-default;
  @include ns-small-button;

  background-color: $tv-success-green;
  border: 1px solid $tv-success-green;
  color: $tv-white;
}

.tv-ns-validation-small-button:hover {
  background-color: $tv-success-green-hover;
  border: 1px solid $tv-success-green-hover;
}

.tv-ns-validation-small-button:disabled {
  background-color: $tv-blueish-grey-shade-6;
  border: 1px solid $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-5;
}

/* Small Special Blue Button */
.tv-ns-small-blue-button {
  @include ns-button-default;
  @include ns-small-button;

  background-color: $tv-accent-02-blue;
  border: 1px solid $tv-accent-02-blue;
  color: $tv-white;
}

.tv-ns-small-blue-button:hover {
  background-color: $tv-white;
  border: 1px solid $tv-accent-02-blue;
  color: $tv-accent-02-blue;
}

.tv-ns-small-blue-button:disabled {
  background-color: $tv-blueish-grey-shade-6;
  border: 1px solid $tv-blueish-grey-shade-6;
  color: $tv-blueish-grey-shade-5;
}
