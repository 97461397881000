@import './src/assets/styles/colors';
@import './src/assets/styles/common-mixins';

.chip-error {
  background-color: $tv-error-red;
  color: $tv-white;
}
.tv-chip-success {
  background-color: $tv-success-green;
  color: $tv-white;
}
.tv-chip-warning {
  background-color: $tv-warning-yellow;
  color: $tv-white;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whole-page-spinner-container {
  @include whole-page-spinner;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* General common styles */
.min-height-50 {
  min-height: 50px;
}

/* Flex direction */
.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

// Custom TV Scroll bar visible on hover
// How to use: put this class on a div with a fixed (or upper-fixed height)
.tv-custom-scroll-bar {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge: Hide scrollbar */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and newer versions of Edge */
  }
  &:hover::-webkit-scrollbar {
    display: block; /* Chrome, Safari: Show scrollbar on hover */
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $tv-blueish-grey-shade-6;
    border-radius: 4px;
  }
  &:hover::-webkit-scrollbar-track {
    background: $tv-blueish-grey-shade-6-transparent-20;
  }
}

// Custom TV Scroll bar always visible
// How to use: put this class on a div with a fixed (or upper-fixed height)
.tv-custom-always-visible-scroll-bar {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge: Hide scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $tv-blueish-grey-shade-6;
    border-radius: 4px;
  }
  &:hover::-webkit-scrollbar-track {
    background: $tv-blueish-grey-shade-6-transparent-20;
  }
}
