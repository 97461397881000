$caliber: 8;

$md-color-primary: (
  50: #ffebef,
  100: #ffccd4,
  200: #fa979c,
  300: #f56d75,
  400: #ff4451,
  500: #ff2734,
  600: #f91934,
  700: #e7002e,
  800: #da0026,
  900: #cd0018,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-color-accent: (
  50: #f5f5f5,
  100: #e9e9e9,
  200: #d9d9d9,
  300: #c4c4c4,
  400: #9d9d9d,
  500: #7b7b7b,
  600: #555555,
  700: #434343,
  800: #262626,
  900: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$text-color: #222323;
$header-background: #fff;

$color-red: #e31635;

$mat-medium: "max-width: 1280px";
$small: 5px;
$medium: 10px;
$large: 20px;
$color-gray: #6f7373;
$color-green: #86bc25;
