.table__data__container {
  position: relative;
  min-height: 200px;
}

.table__data__table-container {
  position: relative;
  max-height: 400px;
  overflow: auto;
  border-radius: 6px;
}

table {
  width: 100%;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;

  .table__data__table__header {
    //font-size: 14px;
    //letter-spacing: 0;
    //line-height: 17px;
  }
}

.table__data__loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Column Widths */
.mat-column-actions {
  max-width: 124px;
}
