@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';
@import './src/assets/styles/buttons';

@mixin main-container-wrapper {
  background-color: $tv-white;
  padding: $tv-xs-padding;
}

@mixin main-dark-blue-container {
  background-color: $tv-blueish-grey-shade-1;
  border-radius: $tv-l-border-radius;
  padding: $tv-m-padding;
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 10px);
}
@mixin whole-page-spinner {
  height: calc(100vh - 100px);
}

@mixin card {
    background-color: $tv-blueish-grey-shade-4-transparent-5;
    width: 250px;
    height: 250px;
    border-radius: 10px;
}

@mixin comment-card {
  background-color: $tv-white;
  margin: $tv-s-margin;
  border-radius: 10px;
  .mat-card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $tv-m-padding;
    border-bottom: 2px solid $tv-blueish-grey-shade-6;
      .comment-info{
        display: flex;
        align-items: center;
        gap: 4px;
        .author {
          font: $tv-new-h4;
          color: $tv-blueish-grey-shade-1;
        }
        .comment-date{
          font: $tv-new-h5;
          color: $tv-blueish-grey-shade-5;
          padding-left: $tv-xl-padding;
        }
      }
      .action-buttons {
      display: flex;
      gap: 8px;
      }
    }
  .body{
    font: $tv-text-s-regular;
    color: $tv-blueish-grey-shade-5;
    margin-top: $tv-m-margin;
    word-wrap: break-word;
  }
}

@mixin light-card {
  background-color: $tv-white;
  border-radius: $tv-m-border-radius;
}

