$tv-blueish-grey-shade-1: #001A33;
$tv-blueish-grey-shade-2: #062440;
$tv-blueish-grey-shade-3: #1F364D;
$tv-blueish-grey-shade-4: #244666;
$tv-blueish-grey-shade-5: #6B90B3;
$tv-blueish-grey-shade-6: #DFEBF7;
$tv-blueish-grey-shade-7: #F2F9FF;

$tv-white: #FFF;

$tv-accent-01-red: #F03C64;
$tv-accent-01-red-hover: #CC3456;
$tv-accent-02-blue: #0083FF;

$tv-error-red: #FF5A6E;
$tv-success-green: #00D78C;
$tv-success-green-hover: #26C18B;
$tv-warning-yellow: #FFC946;

/* TRANSPARENT COLORS */
$tv-accent-01-red-transparent-10: rgba(240, 60, 100, 0.1);
$tv-accent-01-red-transparent-20: rgba(240, 60, 100, 0.2);
$tv-accent-01-red-transparent-60: rgba(240, 60, 100, 0.6);

$tv-accent-02-blue-transparent-10: rgba(0, 131, 255, 0.1);
$tv-accent-02-blue-transparent-20: rgba(0, 131, 255, 0.2);

$tv-success-green-transparent-10: rgba(0, 215, 140, 0.1);

$tv-warning-yellow-transparent-10: rgba(255, 201, 70, 0.1);
$tv-warning-yellow-transparent-20: rgba(255, 201, 70, 0.2);

$tv-error-red-transparent-5: rgba(255, 90, 110, 0.05);
$tv-error-red-transparent-10: rgba(255, 90, 110, 0.1);

$tv-blueish-grey-shade-1-transparent-60: rgba(0, 26, 51, 0.6);
$tv-blueish-grey-shade-1-transparent-80: rgba(0, 26, 51, 0.8);

$tv-blueish-grey-shade-2-transparent-5: rgba(6, 36, 64, 0.05);

$tv-blueish-grey-shade-4-transparent-5: rgba(36, 70, 102, 0.05);
$tv-blueish-grey-shade-4-transparent-10: rgba(36, 70, 102, 0.1);
$tv-blueish-grey-shade-4-transparent-20: rgba(36, 70, 102, 0.2);
$tv-blueish-grey-shade-4-transparent-60: rgba(36, 70, 102, 0.6);

$tv-blueish-grey-shade-5-transparent-5: rgba(107, 144, 179, 0.05);
$tv-blueish-grey-shade-5-transparent-10: rgba(107, 144, 179, 0.1);
$tv-blueish-grey-shade-5-transparent-20: rgba(107, 144, 179, 0.2);
$tv-blueish-grey-shade-5-transparent-30: rgba(107, 144, 179, 0.3);
$tv-blueish-grey-shade-5-transparent-40: rgba(107, 144, 179, 0.4);
$tv-blueish-grey-shade-5-transparent-60: rgba(107, 144, 179, 0.6);

$tv-blueish-grey-shade-6-transparent-10: rgba(223, 235, 247, 0.1);
$tv-blueish-grey-shade-6-transparent-20: rgba(223, 235, 247, 0.2);
$tv-blueish-grey-shade-6-transparent-30: rgba(223, 235, 247, 0.3);
$tv-blueish-grey-shade-6-transparent-40: rgba(223, 235, 247, 0.4);


/* TINTS */
$tv-blueish-grey-3-tint-2: #BBC2C9;
$tv-blueish-grey-3-tint-3: #EEF1F3;
