@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons%7CMaterial+Icons+Outlined");


/* NO FIXED WIDTH CUSTOM DIV THAT CONTAINS TV NEW STYLE SELECT RECT EXTENSIBLE
How to use: Put in a div with a class name : tv-container-select-white-rect-extensible
*/

.tv-container-select-white-rect-extensible {
  padding: 10px 0 10px 0;

  .mat-form-field {
    display: block;
  }

  .mat-form-field-wrapper {
    border: 1px solid $tv-blueish-grey-shade-6;
    border-radius: $tv-xs-border-radius;
    background-color: $tv-white;

    &:hover {
      border: 1px solid $tv-blueish-grey-shade-5-transparent-60;
    }

    padding: 5px 0;
  }

  .mat-form-field-infix {
    font: $tv-text-m-regular;
    color: $tv-blueish-grey-shade-1;
    padding: 10px;
  }

  .mat-form-field-label-wrapper {
    padding: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4;
  }

  .mat-select-value-text {
    color: $tv-blueish-grey-shade-1;
  }

  .mat-form-field-label {
    color: $tv-blueish-grey-shade-5 !important;
    padding-left: $tv-s-padding;
  }

  .mat-select-placeholder {
    color: $tv-blueish-grey-shade-5;
  }

  .mat-input-element {
    color: $tv-blueish-grey-shade-1;
    padding-left: $tv-s-padding;
  }

  .mat-select-arrow {
    color: $tv-blueish-grey-shade-4 !important;
  }
}
