@import './src/assets/styles/colors';
@import './src/assets/styles/fonts';
@import './src/assets/styles/vars';
@import './src/assets/styles/buttons';

/*  ---------------------- ICON BUTTONS ---------------------------*/

/* Round Button Icon Small */
.tv-round-icon-button-icon-small-light-blue {
  @include ns-button-default;
  border-radius: 100px;
  padding: 5px;
  border: 1px solid $tv-blueish-grey-shade-6;
  background-color: $tv-blueish-grey-shade-6;

  mat-icon {
    color: $tv-blueish-grey-shade-1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}

.tv-round-icon-button-icon-small-light-blue:enabled {
  &:hover {
    background-color: $tv-white;
  }
}

.tv-round-icon-button-icon-small-light-blue:disabled {
  border: 1px solid transparent;
  cursor: default;
  background-color: $tv-blueish-grey-shade-6-transparent-30;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
  }
}

/* Round Button Icon Small Dark Blue*/
.tv-round-icon-button-icon-small-dark-blue {
  @include ns-button-default;
  border-radius: 100px;
  padding: 5px;
  border: 1px solid $tv-blueish-grey-shade-4;
  background-color: $tv-blueish-grey-shade-4;

  mat-icon {
    color: $tv-blueish-grey-shade-6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }
}

.tv-round-icon-button-icon-small-dark-blue:enabled {
  &:hover {
    background-color: $tv-white;

    mat-icon {
      color: $tv-blueish-grey-shade-4;
    }
  }
}

.tv-round-icon-button-icon-small-dark-blue:disabled {
  border: 1px solid transparent;
  cursor: default;
  background-color: $tv-blueish-grey-shade-6-transparent-30;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
  }
}

/* Round Button Icon Medium */
.tv-round-icon-button-icon-medium-light-blue {
  @include ns-button-default;
  border-radius: 100px;
  padding: 5px;
  border: 1px solid $tv-blueish-grey-shade-6;
  background-color: $tv-blueish-grey-shade-6;

  mat-icon {
    color: $tv-blueish-grey-shade-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
}

.tv-round-icon-button-icon-medium-light-blue:enabled {
  &:hover {
    background-color: $tv-white;
  }
}

.tv-round-icon-button-icon-medium-light-blue:disabled {
  border: 1px solid transparent;
  cursor: default;
  background-color: $tv-blueish-grey-shade-6-transparent-30;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
  }
}

/* Round Button Icon Medium Dark Blue*/
.tv-round-icon-button-icon-medium-dark-blue {
  @include ns-button-default;
  border-radius: 100px;
  padding: 5px;
  border: 1px solid $tv-blueish-grey-shade-4;
  background-color: $tv-blueish-grey-shade-4;

  mat-icon {
    color: $tv-blueish-grey-shade-6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
}

.tv-round-icon-button-icon-medium-dark-blue:enabled {
  &:hover {
    background-color: $tv-white;

    mat-icon {
      color: $tv-blueish-grey-shade-4;
    }
  }
}

.tv-round-icon-button-icon-medium-dark-blue:disabled {
  border: 1px solid transparent;
  cursor: default;
  background-color: $tv-blueish-grey-shade-6-transparent-30;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
  }
}

/*  --------------- Clear Input Button ---------------------*/

.tv-clear-input-icon-button {
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
}

/* ngClass depending on value*/
.tv-visible-clear-btn {
  visibility: visible;
}

.tv-invisible-clear-btn {
  visibility: hidden;
}

/* ------------ Transparent Button Icon ------------ */
.tv-icon-button-transparent {
  @include ns-button-default;
  padding: 5px;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
}

/* ------------ Transparent Button Icon Container -------------- */
// How to use : Put Button into div and add this class to the div.
.tv-icon-button-transparent-container {
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
}


/* Round Button Icon PAGE NAV Light Blue on dark background */
.tv-round-icon-page-nav-button-light-blue-on-dark-bg {
  @include ns-button-default;
  border-radius: 100px;
  padding: 5px;
  border: 1px solid $tv-blueish-grey-shade-6;
  background-color: $tv-blueish-grey-shade-6;

  mat-icon {
    color: $tv-blueish-grey-shade-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 16px;
    height: 16px;
  }
}

.tv-round-icon-page-nav-button-light-blue-on-dark-bg:enabled {
  &:hover {
    background-color: $tv-white;
  }
}

.tv-round-icon-page-nav-button-light-blue-on-dark-bg:disabled {
  cursor: default;
  background-color: $tv-blueish-grey-shade-2;
  border: 1px solid $tv-blueish-grey-shade-2;
  color: $tv-blueish-grey-shade-4;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
  }
}

// RATE

/* Round Button Icon Medium */
.tv-chip-icon-rate-button-medium-light-blue {
  @include ns-button-default;
  border-radius: $tv-xs-border-radius;
  //padding: $tv-xs-padding;
  border: 1px solid $tv-blueish-grey-shade-6;
  background-color: $tv-accent-02-blue-transparent-10;
  width: $tv-25-width;
  height: 60px;
  margin: 1px;
  padding: 1px;

  mat-icon {
    color: $tv-blueish-grey-shade-4;
  }
}

/* ROUND LIGHT BLUE BUTTON FOR HEADERS WITH ROUND INPUTS */
.tv-round-header-icon-button-light-blue-on-dark-bg {
  @include ns-button-default;
  border-radius: 100px;
  padding: 5px;
  border: 1px solid $tv-blueish-grey-shade-6;
  background-color: $tv-blueish-grey-shade-6;

  mat-icon {
    color: $tv-blueish-grey-shade-4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}

.tv-round-header-icon-button-light-blue-on-dark-bg:enabled {
  &:hover {
    background-color: $tv-white;
  }
}

.tv-round-header-icon-button-light-blue-on-dark-bg:disabled {
  cursor: default;
  border: 1px solid $tv-blueish-grey-shade-4;
  background-color: $tv-blueish-grey-shade-6-transparent-10;

  mat-icon {
    color: $tv-blueish-grey-shade-5;
  }
}
