/* You can add global legacy-styles to this file, and also import other style files */
@import "assets/legacy-styles/reset.scss";
@import "assets/legacy-styles/theme.scss";
@import "assets/legacy-styles/common.scss";
@import "assets/legacy-styles/colors.scss";
@import "assets/legacy-styles/buttons.scss";
@import "assets/legacy-styles/table.scss";

/* NEW TV STYLES */
@import "assets/styles/colors";
@import "assets/styles/fonts";
@import "assets/styles/vars";
@import "assets/styles/buttons";
@import "assets/styles/icon-buttons";
@import "assets/styles/mat-ui-styles";
@import "assets/styles/mat-ui-diff-styles";
@import "assets/styles/mat-ui-buttons";
@import "assets/styles/common-styles";


html,
body {
  height: calc(100% - 80px); // 80px is the hearder height
  color: #222323;
  background: #f0f1f1;
}

.page__header {
  width: 100%;
  margin-bottom: 30px;

  p {
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
}

.page__return-link {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 20px;
  color: #222323;
  text-decoration: none;
  display: block;

  mat-icon {
    color: $color-red;
    vertical-align: middle;
  }
}

.mat-tab-label {
  background: #f7f8f8 !important;
}

.mat-tab-label[aria-selected="true"] {
  background-color: #fff !important;
  color: $color-red;
}

.mat-tab-body-content {
  padding-left: 1px;
  padding-right: 1px;
}

.inline-display {
  display: inline-block;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.margin-medium-right {
  margin-right: $medium !important;
}

.border-left {
  border-left: 2px solid #e8ebee;
  height: calc(33% + 15px);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.mat-button-rounded {
  display: inline-block !important;
  padding: 0 !important;
  min-width: 40px !important;
  line-height: 40px !important;
  border-radius: 50% !important;
  color: $text-muted !important;

  .mat-icon {
    height: initial !important;
  }
}
.mat-dialog-container {
  padding: 36px !important;
}

h2[mat-dialog-title] {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 29px;
  text-align: center;
}

* {
  box-sizing: border-box;
}
.width-10 {
  width: 10%;
}
.width-35 {
  width: 35%;
}
.width-25 {
  width: 25%;
}
.width-20 {
  width: 20%;
}
.width-55 {
  width: 55%;
}
.width-45 {
  width: 45%;
}

.pointer {
  cursor: pointer;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-top-3 {
  padding-top: 3px;
}

.vertical-middle {
  vertical-align: middle;
}

.margin-auto {
  margin: auto;
}
.lighter {
  font-weight: lighter;
}
.bolder {
  font-weight: bolder;
}
.underline {
  text-decoration: underline;
}

.flex {
  display: flex;
}
.font-size-13 {
  font-size: 13px !important;
}
.flex-column {
  display: flex;
  flex-flow: column;
}
.font-size-15 {
  font-size: 15px !important;
}

.main-title {
  height: 44px;
  color: #222323;
  font-family: "FranceTV Brown";
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
}

.title-lineaire {
  font-family: FranceTV Brown;
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 29px;
  color: #6b90b3;
}

.cgrp-level-1 {
  background-color: $color-cgrp-level-1;
}

.cgrp-level-2 {
  background-color: $color-cgrp-level-2;
}

.cgrp-level-3 {
  background-color: $color-cgrp-level-3;
}

.cgrp-level-4 {
  background-color: $color-cgrp-level-4;
}

.cgrp-level-5 {
  background-color: $color-cgrp-level-5;
}

.cgrp-level-6 {
  background-color: $color-cgrp-level-6;
}
