@import "./vars.scss";
@import "@angular/material/theming";

$md-primary: (
  50: #ffebef,
  100: #ffccd4,
  200: #fa979c,
  300: #f56d75,
  400: #ff4451,
  500: #ff2734,
  600: #f91934,
  700: #e7002e,
  800: #da0026,
  900: #cd0018,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-accent: (
  50: #f5f5f5,
  100: #e9e9e9,
  200: #d9d9d9,
  300: #c4c4c4,
  400: #9d9d9d,
  500: #7b7b7b,
  600: #555555,
  700: #434343,
  800: #262626,
  900: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$app-primary: mat-palette($md-primary, 700);
$app-accent: mat-palette($md-accent);
$app-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
$text-color: #222323;
$text-color-2: #6f7373;
$text-muted: #abadb1;
$text-warning: #ee565c;
$background-color: #f0f1f1;
$background-color-2: #fafafa;
$background-color-header: #e8ebee;
/* Grid colors */

$cell-clicked-bg: #ffffff;
$available-bg-color: #e5f4e3;
$available-border-color: #5fb954;
$optimized-bg-color: #fde8d1;
$optimized-border-color: #f59f40;
$unavailable-bg-color: #dde1e6;
$unavailable-border-color: #abadb1;
$booked-bg-color: #4670d1;
$booked-border-color: #224492;
$out-of-time-color: #ff9579;
$next-day-color: $text-warning;
$today-color: #0091ff;
$past-color: #6f7373;

/* Screen State */
// blue
$no_state_screen-bg-color: #85c1e9;
$no_state_screen-border-color: #3498db;

// grey
$forbidden_screen-bg-color: #cacfd2;
$forbidden_screen-border-color: #616a6b;

// white
$not_usable_screen-bg-color: #fdfefe;
$not_usable_screen-border-color: #34495e;

// yellow
$subst_with_crossover-bg-color: #fcf3cf;
$subst_with_crossover-border-color: #f5cc40;

// green
$subst_with_crossover_subst_ok-bg-color: #2ecc71;
$subst_with_crossover_subst_ok-border-color: #239b56;

// red
$subst_with_crossover_subst_ko-bg-color: #d98880;
$subst_with_crossover_subst_ko-border-color: #e41536;

// orange
$subst_without_crossover-bg-color: #f8c471;
$subst_without_crossover-border-color: #ee7656;

// CgrpNet 30s
$color-cgrp-level-1: #e4e8ff; // < 1000
$color-cgrp-level-2: #b6bddb; // >=1000 < 2000
$color-cgrp-level-3: #a1b2ed; // >=2000 < 3000
$color-cgrp-level-4: #7b92e0; // >=3000 < 4000
$color-cgrp-level-5: #4680fa; // >=4000 < 5000
$color-cgrp-level-6: #0055ff; // >= 5000

.text-success {
  color: $available-border-color !important;
}
.text-red {
  color: $color-red !important;
}
.text-gray {
  color: $color-gray !important;
}
